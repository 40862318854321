<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">

        <h5>Linha do Tempo</h5>
        <Timeline :value="customEvents" align="alternate" class="customized-timeline">
          <template #marker="slotProps">
                <span class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-2" :style="{backgroundColor: slotProps.item.color}">
                    <i :class="slotProps.item.icon"></i>
                </span>
          </template>
          <template #content="slotProps">
            <Card>
              <template #title>
                {{slotProps.item.status}}
              </template>
              <template #subtitle>
                {{slotProps.item.date}}
              </template>
              <template #content>
<!--                <img v-if="slotProps.item.image" :src="'demo/images/product/' + slotProps.item.image" :alt="slotProps.item.name" width="200" class="shadow-2 mb-3" />-->
                <p>{{ slotProps.item.usuario}} </p>
                <Button label="Abrir detalhes" class="p-button-text"></Button>
              </template>
            </Card>
          </template>
        </Timeline>


      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      customEvents: [
        {
          status: 'Contato Efetivado',
          date: '15/10/2022 10:30',
          icon: 'pi pi-volume-up',
          color: '#9C27B0',
          image: 'game-controller.jpg',
          usuario: 'Jose da Silva'
        },
        {
          status: 'Processando informações',
          date: '15/10/2022 14:00',
          icon: 'pi pi-cog',
          color: '#673AB7',
          usuario: 'Administrador'
        },
        {
          status: 'Proposta Efetivada',
          date: '15/10/2022 16:15',
          icon: 'pi pi-envelope',
          color: '#FF9800',
          usuario: 'Jose da Silva'
        },
        {
          status: 'Proposta Convertida em Pedido',
          date: '16/10/2022 10:00',
          icon: 'pi pi-check',
          color: '#607D8B',
          usuario: 'Jose da Silva'
        }
      ],
      horizontalEvents: [
        '2022', '2021', '2022', '2023'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.customized-timeline) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}

</style>
